import leisureConst from "./leisureConst";
import sportsConst from "./sportsConst";
import inplayConst from "./inplayConst";
import {
    RECEIVE_INPLAY_CART_ITEM,
    RECEIVE_INPLAY_CART_ITEM_DEL,
    RECEIVE_SPORTS_CART_ITEM,
    RECEIVE_SPORTS_CART_ITEM_DEL
} from "../store/mutation-types";
import {mapGetters} from "vuex";

export const loginRegisterMixin = {
    computed: {
        testRegxUserName() {
            return /^[a-z][0-9a-z]{3,13}$/.test(this.user.username);
        },
        testRegxPasswd() {
            return /^.{4,29}$/.test(this.user.passwd);
        },
        testRegxNickname() {
            return /^[가-힣0-9a-z]{2,6}$/.test(this.user.nickname)
        }
    }
}

export const leisureGameMixin = {
    data() {
        return {
            showMasker: true,
            leisureConst,
            sportsConst,
            leisureGameInfo: {
                betStopLimitSecond: 0,
                leisureGame: null,
                leisureSportsGames: [],
                now: null,
                beforEndSecond: 10000
            },
            attrConfig: [],
            //사디리
            oddevenConfig: {},
            leftrightConfig: {},
            lineConfig: {},
            overunderConfig: {},
            //달팽이
            winnumberConfig: {},
            //fx
            jonggasellbuyConfig: {},
            cegasellbuyConfig: {},
            //bet365가상축구
            spConfig: {},
            souConfig: {},
            spGame: {homeTeamName: '', awayTeamName: '', homeTeamOdds: 0, drawOdds: 0, awayTeamOdds: 0},//승무패
            souGame: {homeTeamName: '', awayTeamName: '', homeTeamOdds: 0, drawOdds: 0, awayTeamOdds: 0},//오버언더
            //bet365가상개경주
            dograceConfig: {},
            dograceGame: {bedang1: 0, bedang2: 0, bedang3: 0, bedang4: 0, bedang5: 0, bedang6: 0},

            //파워볼
            poddevenConfig: {},
            poverunderConfig: {},
            psectionConfig: {},
            pnumberConfig: {},
            poddevenoverunderConfig: {},
            //일반볼
            noddevenConfig: {},
            noverunderConfig: {},
            nsectionConfig: {},
            nbmsConfig: {}, //일반볼 소,중,대
            noddevenoverunderConfig: {},
            noddevenbmsConfig: {},

            selectedInfo: {
                selectedAttr: 0,
                selectedGameId: 0,
                selectedWay: 0,
                seletedOdd: 0,
                selectText: '',
                config: {maxBetCash: 0, minBetCash: 0, maxWinCash: 0}
            }
        }
    },
    methods: {
        selected(attr, gameId, way, odd, config, selectText) {
            console.log(gameId,way)
            this.selectedInfo.selectedAttr = attr
            this.selectedInfo.selectedGameId = gameId
            this.selectedInfo.selectedWay = way
            this.selectedInfo.seletedOdd = odd
            this.selectedInfo.selectText = selectText
            this.selectedInfo.config = config
        },
        refreshTimer() {
            this.initLeisureGame()
        },
        timerStop() {
            this.showMasker = true
            console.log(this.leisureGameInfo.beforEndSecond)
            setTimeout(() => {
                this.initLeisureGame();
            }, this.leisureGameInfo.beforEndSecond)
        },
        resetLeiserGameSelected() {
            this.selectedInfo.selectedAttr = 0
            this.selectedInfo.selectedGameId = 0
            this.selectedInfo.selectedWay = 0
            this.selectedInfo.seletedOdd = 0
            this.selectedInfo.selectText = ''
        },
        initLeisureGame() {
            getOnBetLeisureGames(this.kind, this.$moment().millisecond()).then(res => {
                if (res.data.success) {

                    this.$set(this.leisureGameInfo, 'betStopLimitSecond', res.data.data.betStopLimitSecond)
                    this.$set(this.leisureGameInfo, 'leisureGame', res.data.data.leisureGame)
                    this.$set(this.leisureGameInfo, 'leisureSportsGames', res.data.data.leisureSportsGames)
                    this.$set(this.leisureGameInfo, 'now', res.data.data.now)

                    //Bet365가상축구일 경우
                    if (this.kind === leisureConst.LEISURE_KIND_BET365_SOCCER_PREMIERSHIP
                        || this.kind === leisureConst.LEISURE_KIND_BET365_SOCCER_SUPERLEAGUE
                        || this.kind === leisureConst.LEISURE_KIND_BET365_SOCCER_EUROCUP
                        || this.kind === leisureConst.LEISURE_KIND_BET365_SOCCER_WORLDCUP) {
                        this.leisureGameInfo.leisureSportsGames.forEach((game) => {
                            if (game.sportsKind === sportsConst.GAME_KIND_1X2) {
                                this.spGame = game;
                            }
                            if (game.sportsKind === sportsConst.GAME_KIND_OVERUNDER) {
                                this.souGame = game;
                            }

                        })
                    }
                    if (this.kind === leisureConst.LEISURE_KIND_BET365_DOGRACE_GOLDENHILLPARK
                        || this.kind === leisureConst.LEISURE_KIND_BET365_DOGRACE_HILLSIDEPARK) {
                        this.dograceGame = this.leisureGameInfo.leisureGame
                        this.dograceGame.bedang1 = this.dograceGame.bedang1.toFixed(2)
                        this.dograceGame.bedang2 = this.dograceGame.bedang2.toFixed(2)
                        this.dograceGame.bedang3 = this.dograceGame.bedang3.toFixed(2)
                        this.dograceGame.bedang4 = this.dograceGame.bedang4.toFixed(2)
                        this.dograceGame.bedang5 = this.dograceGame.bedang5.toFixed(2)
                        this.dograceGame.bedang6 = this.dograceGame.bedang6.toFixed(2)
                    }

                    this.$refs.ltimer.startTimeCount();
                    this.showMasker = false

                } else {
                    this.showMasker = true
                    this.$refs.ltimer.setSequenceText(res.data.msg)
                    /*setTimeout(() => {
                      this.initLeisureGame()
                    }, 2000)*/
                }
            })
        },
    },
    created() {
        this.initLeisureGame()
        this.initLeisureGameArttributeConfig()
        this.$bus.$on('leisureBetSuccess', () => {
            this.resetLeiserGameSelected()
        })
    }
}


export const sportsFromBetAPI = {
    props: {
        item: null,
    },
    data() {
        return {
            inplayConst,
            sportsConst
        }
    },
    computed: {},
    methods: {
        selectItem(game, way, text, id) {

            if(way === sportsConst.WAY_HOME && (parseInt(game.homeSu)=== 1 || game.homeOdds <= 1)){
                return false;
            }

            if(way === sportsConst.WAY_AWAY && (parseInt(game.awaySu) === 1 || game.awayOdds <= 1)){
                return false;
            }
            if(way === sportsConst.WAY_DRAW && (parseInt(game.drawSu) === 1 || game.drawOdds <= 1)){
                return false;
            }
            this.$bus.$emit('addInplayGame2BetCart', {game: game, way: way, text: text, selectId: id});
        },
    }
}

