<template>

    <div>
        <!--탑메뉴-->
        <topbar-comp></topbar-comp>
        <left-bar-comp>
            <sports-left-bar-comp></sports-left-bar-comp>
        </left-bar-comp>

        <div class="content_section">
            <div class="content">
                <login-links-comp></login-links-comp>
                <div class="logo_panel" style="padding-top: 50px">
                    <div class="logo">
                        <img src="../../assets/images/logo/oneplay/oneplay_logo.png">
                    </div>
                    <div class="ment line">
                        스포츠 게임 <span style="color: #079e39">원플레이</span>에 오신것을 환영합니다.
                    </div>
                    <div class="ment mt10" style="font-size: 12px;color: #c4c4c3">
                        <p>국내 ONEPLAY는 만 18세 이상만 이용 가능한 서비스입니다. (미성년자는 가입할 수 없습니다)</p>
                        <p>원플레이에서는 회원 당 1개의 계정만 가입이 가능합니다.</p>
                    </div>

                    <div class="terms_box">
                        <div class="join-container">
                            <div class="form-group">
                                <div class="labels">
                                    <p>본인인증</p>
                                </div>
                                <div class="infos">
                                    <button class="btn03 btn_check_phone" @click="authphone">휴대폰인증</button>
                                    <button class="btn03 btn_check_phone text-primary">아이핀인증</button>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="labels">
                                    <p>아이디</p>
                                </div>
                                <div class="infos">
                                    <label>
                                        <input type="text" v-model="user.username"
                                               placeholder="아이디">
                                        <button class="btn03 btn_checkr_epet" @click="checkUserName">중복확인</button>
                                    </label>

                                </div>
                                <p class="rem-txt">{{error.username}}</p>
                            </div>
                            <div class="form-group">
                                <div class="labels">
                                    <p>닉네임</p>
                                </div>
                                <div class="infos">
                                    <label>
                                        <input type="text" v-model="user.nickname"
                                               placeholder="닉네임">
                                        <button class="btn03 btn_checkr_epet" @click="checkNickName">중복확인</button>
                                    </label>
                                </div>
                                <p class="rem-txt">{{error.nickname}}</p>
                            </div>
<!--                            <div class="form-group">-->
<!--                                <div class="labels">-->
<!--                                    <p>휴대전화</p>-->
<!--                                </div>-->
<!--                                <div class="infos">-->
<!--                                    <label>-->
<!--                                        <input type="number" v-model="user.phone" @change="checkPhone" pattern="[0-9]*"-->
<!--                                               placeholder="`-` 없이 숫자만 입력">-->
<!--                                    </label>-->
<!--                                </div>-->
<!--                                <p class="rem-txt">{{error.phone}}</p>-->
<!--                            </div>-->
                            <div class="form-group">
                                <div class="labels">
                                    <p>이메일</p>
                                </div>
                                <div class="infos">
                                    <label>
                                        <input type="email" v-model="user.email" placeholder="example@mail.com">
                                    </label>
                                </div>
                                <p class="rem-txt">{{error.email}}</p>
                            </div>
                            <div class="form-group">
                                <div class="labels">
                                    <p>비밀번호</p>
                                </div>
                                <div class="infos">
                                    <label>
                                        <input type="password" v-model="user.passwd" @change="checkPasswd"
                                               placeholder="비밀번호">
                                    </label>
                                </div>
                                <p class="rem-txt">{{error.passwd}}</p>
                            </div>
                            <div class="form-group">
                                <div class="labels">
                                    <p>비밀번호확인</p>
                                </div>
                                <div class="infos">
                                    <input type="password" v-model="user.confirm_password"
                                           @change="checkConfirmPassword"
                                           placeholder="비밀번호 확인">
                                </div>
                                <p class="rem-txt">{{error.confirm_password}}</p>
                            </div>
                            <div class="form-group">
                                <div class="labels">
                                    <p>추천인</p>
                                </div>
                                <div class="infos">
                                    <label>
                                        <input type="text" v-model="user.code" placeholder="추천인">
                                    </label>
                                </div>
                                <p style="color: #c4c4c3;font-size: 10px;padding-top: 5px;text-align: left;padding-left: 20px">* 추천인 미입력시에도 이용가능합니다</p>
                            </div>
                            <div class="form-group">
                                <div class="labels">
                                    <p>수신동의</p>
                                </div>
                                <div class="infos" style="display: flex;justify-content: left">
                                    <input id="emailrec" type="checkbox" v-model="user.emailrec" style="width: 18px;">
                                    <label for="emailrec" style="line-height: 40px;height: 40px;padding-left: 3px;"> *
                                        이메일 수신동의</label>
                                    <input id="smsrec" type="checkbox" v-model="user.smsrec"
                                           style="width: 18px;margin-left: 5px">
                                    <label for="smsrec" style="line-height: 40px;height: 40px;padding-left: 3px;"> * SMS
                                        수신동의</label>
                                </div>
                                <div style="display: block;margin-top: 0px;text-align: left;font-size: 10px;color: #f4c600">
                                    원플레이의 다양한 서비스 및 이벤트 안내를 동의 합니다.
                                </div>
                            </div>

                        </div>
                    </div>
                    <!-- 본인인증 서비스 팝업을 호출하기 위해서는 다음과 같은 form이 필요합니다. -->
                    <form name="form_chk" method="post">
                        <input type="hidden" name="m" value="checkplusService">						<!-- 필수 데이타로, 누락하시면 안됩니다. -->
                        <input type="hidden" name="EncodeData" v-model="sencData">
                    </form>

                    <div style="text-align: center;font-size: 11px; color: #9f9fa1;box-sizing: border-box;padding: 2px 5px">
                        회원정보는 개인정보취급방침에 따라 안전하게 보호되며 회원님의 명백한 동의 없이 공개 또는 제3자에게 제공되지 않습니다.
                    </div>

                    <div class="buttons">
                        <button class="btn" @click="doRegister" style="color: #f4c600;"><i
                                class="fa fa-check-square"></i> 가입하기
                        </button>
                        <router-link to="/main" tag="button" class="btn"><i class="fa fa-close"></i> 취소</router-link>
                    </div>

                </div>

            </div>
        </div>

        <!-- right 메뉴 -->
        <right-bar-comp>
            <right-bar-banner-comp slot="rightBanner"></right-bar-banner-comp>
        </right-bar-comp>
        <foot-comp></foot-comp>

    </div>
</template>

<script>
    import {checkUserName, checkNickName, getVerificationCode, register, getSencdata} from "../../network/userRequest";
    import {loginRegisterMixin} from "../../common/mixin";
    import TopbarComp from "../../components/TopbarComp";
    import LeftBarComp from "../../components/LeftBarComp";
    import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
    import LoginLinksComp from "../../components/links/LoginLinksComp";
    import RightBarComp from "../../components/RightBarComp";
    import RightBarBannerComp from "../../components/RightBarBannerComp";
    import FootComp from "../../components/FootComp";

    export default {
        name: "register",
        components: {
            FootComp,
            RightBarBannerComp, RightBarComp, LoginLinksComp, SportsLeftBarComp, LeftBarComp, TopbarComp
        },
        mixins: [loginRegisterMixin],
        data() {
            return {
                user: {
                    username: '',
                    passwd: '',
                    confirm_password: '',
                    nickname: '',
                    phone: '',
                    email: '',
                    smsrec: 1,
                    emailrec: 1,
                    code: ''
                },
                error: {
                    username: '',
                    passwd: '',
                    confirm_password: '',
                    nickname: '',
                    phone: '',
                    email: '',
                },
                status: {
                    username: true,
                    passwd: true,
                    confirm_password: true,
                    nickname: true,
                    phone: true,
                    email: true,
                    code: true
                },
                isUsePhoneCode: false,
                sencData : null,
            }
        },
        methods: {
            authphone(){
                this.fnPopup()
            },
            fnPopup(){
                window.open('', 'popupChk', 'width=395, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');
                document.form_chk.action = "https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb";
                document.form_chk.target = "popupChk";
                document.form_chk.submit();
            },
            checkUserName() { //회원가입시 아이디 체크
                if (!this.testRegxUserName) {
                    this.error.username = '아이디는 4-15자리 영문,숫자조합입니다'
                    this.status.username = false;
                    return false;
                }
                this.error.username = '';
                //아이디 사용가능 여부 체크
                checkUserName(this.user.username).then(res => {
                    if (res.data.success) {
                        this.error.username = ''
                        this.status.username = true;
                        this.$swal({
                            title: '사용가능한 아이디 입니다',
                            type: 'success',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                        return true;
                    } else {
                        this.error.username = res.data.msg
                        this.status.username = false;
                        return false;
                    }
                })
            },
            checkNickName() { //회원가입시 닉네임 체크
                if (!this.testRegxNickname) {
                    this.error.nickname = '닉네임은 2-6자리 한글,영문,숫자로 가능합니다'
                    this.status.nickname = false;
                    return false;
                }
                this.error.username = '';
                //아이디 사용가능 여부 체크
                checkNickName(this.user.nickname).then(res => {
                    if (res.data.success) {
                        this.error.nickname = ''
                        this.status.nickname = true;
                        this.$swal({
                            title: '사용가능한 닉네임 입니다',
                            type: 'success',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                        return true;
                    } else {
                        this.error.nickname = res.data.msg
                        this.status.nickname = false;
                        return false;
                    }
                })
            },
            checkPasswd() {
                if (!this.testRegxPasswd) {
                    this.error.passwd = '비밀번호는 4-20자리 입니다';
                    this.status.passwd = false;
                    return false;
                } else {
                    this.error.passwd = '';
                    this.status.passwd = true;
                    return true;
                }
            },
            checkConfirmPassword() {
                if (this.user.confirm_password.trim() === '' || this.user.passwd.trim() !== this.user.confirm_password.trim()) {
                    this.error.confirm_password = '입력하신 비밀번호가 동일하지 않습니다'
                    this.status.confirm_password = false;
                    return false;
                } else {
                    this.error.confirm_password = '';
                    this.status.confirm_password = true;
                    return true;
                }
            },
            checkPhone() {
                if (!/^[0-9]{11,12}$/.test(this.user.phone)) {
                    this.error.phone = '폰번호 오류입니다.`-`없이 숫자로만 입력하세요'
                    this.status.phone = false;
                    return false;
                } else {
                    this.error.phone = '';
                    this.status.phone = true;
                    return true;
                }
            },
            checkEmail() {
                if (!/^([A-Za-z0-9_\-.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(this.user.email.trim())) {
                    this.error.email = '이메일격식 오류입니다.'
                    this.status.email = false
                    return false
                } else {
                    this.error.email = '';
                    this.status.email = true
                    return true
                }
            },
            checkCode() {
                if (this.user.code.trim().length > 0 && !/^[0-9a-zA-Z]{2,10}$/.test(this.user.code)) {
                    this.error.code = '가입코드를 정확하게 입력하세요';
                    this.status.code = false;
                    return false
                } else {
                    this.error.code = '';
                    this.status.code = true;
                    return true
                }
            },

            doRegister() {
                // if (!this.checkPhone()) {
                //     console.log('phone')
                //     return false;
                // }
                if (this.user.emailrec && !this.checkEmail()) {
                    console.log('email')
                    return false;
                }
                if (!this.checkPasswd()) {
                    console.log('checkpasswd')
                    return false;
                }
                if (!this.checkConfirmPassword()) {
                    console.log('checkpasswd2')
                    return false;
                }
                if (!this.checkCode()) {
                    console.log('checkcode')
                    return false;
                }
                register(this.user).then(res => {
                    if (res.data.success) {
                        alert('회원가입을 축하드립니다.');
                        this.$router.replace('/login')
                    } else {
                        alert(res.data.msg);
                    }
                })
            },

        },
        created() {
            getSencdata(1).then(res=>{
                if(res.data.success){
                    this.sencData = res.data.data

                }else {
                    this.$swal({
                        title: res.data.msg,
                        type: 'error',
                        showCancelButton: false,
                        showConfirmButton: true
                    })
                }
            })
        }
    }
</script>

<style scoped>
    @import url("../../assets/css/lore.css");
</style>